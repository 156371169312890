<style>
.back-arrow {
  padding-top: 18px;
  padding-left: 0px;
}

.brand-logo {
  padding-left: 90px;
}

@media (max-width: 600px) {
  .brand-logo {
    padding-left: 15px;
  }

  .back-arrow {
    position: absolute;
    left: 12px;
    padding-top: 3px;
  }

  .back-arrow img {
    width: 10px;
  }
}
</style>
<template>
  <div class="col-12 ps-3 pe-3 d-flex  justify-content-end theme-header">
    <router-link :to="{ name: 'DashboardHome' }" :class="backButtonEnable ? 'circle back-arrow' : 'd-none'">
      <img src="/img/ui-theme/back-arrow.png" alt="Fullscreen">
    </router-link>

    <div :class="backButtonEnable ? 'm-auto pt-3' : 'm-auto pt-3 brand-logo'">
      <div class="d-flex flex-column" v-if="showLogo">
        <img src="/img/ui-theme/sheratonhb.png" alt="" height="145">
        <!-- <img src="/img/ui-theme/Ellipse 1.png" style="height:24.29px; width:39px; margin:15px auto;" alt=""> -->
      </div>
    </div>
    <a  :href="generateMailToLink"  class="circle" type="button" >
      <img src="/img/ui-theme/chat-heart.png" alt="Fullscreen">
    </a>

    <ModalComponent v-if="open" @updateOpenValue="openModal"></ModalComponent>
  </div>
</template>

<script>
import global from '../global'
import { unslugify } from "unslugify";
import ModalComponent from './Modal.vue';

export default {
  name: 'TypeTopNav',
  props: [
    'heading',
    'backButton',
    'showLogo'
  ],
  data() {
    return {
      open: false,
      backButtonEnable: this.backButton === "true",
      showLogo: this.showLogo === "true",
      toEmail: ['councillors@frasercoast.qld.gov.au'],
      bccEmail: ['herveybaycommunity@gmail.com'],
      subject: 'PLEASE APPROVE THE SHERATON HERVEY BAY',
      body: "Dear Councillors,\n\n I am a resident of the Fraser Coast writing to you to indicate my support for the Sheraton Hervey Bay project.\n\nI want the project to go ahead for the following reasons …."
    };
  },
  components: {
    ModalComponent,
  },
  computed: {
    generateMailToLink() {
      const toParam = encodeURIComponent(this.toEmail);
      const bccParam = encodeURIComponent(this.bccEmail);
      const subjectParam = encodeURIComponent(this.subject);
      const bodyParam = encodeURIComponent(this.body);

      const mailtoLink = `mailto:${toParam}?&subject=${subjectParam}&body=${bodyParam}&bcc=${bccParam}`;
      return mailtoLink;
    }
  },
  setup() {
    const { theme } = global
    let displayHeading = ""

    return {
      theme, displayHeading
    }
  },
  created() {
    // this.displayHeading = unslugify(this.heading)
  },
  methods: {
    openModal() {
      this.open = !this.open;
    },
  },
};
</script>
